<template>
  <v-layout 
    class="eval-intent"
    
    v-if="message.intent"
    outlined
  >
    <v-layout outlined shrink>
      <v-layout column class="open pr-1"
        :class="`${this.$vuetify.theme.dark ? 'secondary-background' : 'primary-background'}`"
        justify-center v-ripple @click="viewSuggestion = !viewSuggestion">
        <v-icon small color="grey darken-1"
          :class="viewSuggestion ? 'rotate-arrow' : ''">keyboard_arrow_down
        </v-icon>
      </v-layout>
      <transition name="slide-x-transition">
        <v-layout :style="viewSuggestion ? 'margin-left: -7px' : ''"
          v-if="viewSuggestion" class="d-flex">
          <v-layout class="d-flex flex-column flex-grow-1">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-hover>
                  <v-toolbar
                    style="border-top-right-radius: 7px; padding: 0px 10px"
                    class="flex-grow-0 elevation-0 align-self-start lighten-4"
                    height="30" :color="
                    colorFromString(message.intent)
                  ">
                    <span class="white--text ml-8">{{
                    message.intent
                  }}</span>
                    <v-layout v-if="!debug">
                      <v-icon class="ml-4 white--text eval-button visible" v-on="on" 
                      :style="
                          'cursor: default'
                        " :color="
                          !message.bad_classification ? 'white' : 'red'
                        " size="20" @click="
                          !message.bad_classification
                            ? saveEvalIntent(message.id)
                            : null
                        ">
                        thumb_down
                      </v-icon>

                    </v-layout>
                  </v-toolbar>
                </v-hover>
              </template>
              <span>{{
                !message.bad_classification
                  ? "Desclassificar sugestão?"
                  : "Sugestão desclassificada"
              }}</span>
            </v-tooltip>
            <v-layout class="align-center justify-center flex-grow-1"
              :class="`${this.$vuetify.theme.dark ? 'secondary-background' : 'primary-background'}`"
            >
              <span class="text-caption font-weight-light">{{ percentage(message.accuracy) }}</span>
            </v-layout>
          </v-layout>
        </v-layout>
      </transition>
    </v-layout>
  </v-layout>
</template>

<script>
import { stringToColor } from "@/mixins/colorMixin"
import { mapActions } from "vuex"
import RasaConversationService from '@/services/conversation'

export default {
  props: {
    message: Object,
    debug: Boolean,
  },
  mixins: [
    stringToColor
  ],
  data() {
    return {
      viewSuggestion: true,
    };
  },

  methods: {
    ...mapActions("rasaConversation", {
      badClassification: "badClassification",
    }),
    percentage(value) {
      return (parseFloat(value) * 100).toFixed(2) + "%";
    },
    saveEvalIntent(message_id) {
      RasaConversationService.mark_classification_as_wrong(message_id).then((res) => { 
          this.badClassification(message_id)
          this.$store.commit("showSnackbar", "Mensagem marcada com sucesso");
        }
      ).catch(
        this.$store.commit("showSnackbar", "Erro ao marcar a mensagem")
      )
    }
  },

  computed: {}
}
</script>

<style lang="scss" scoped>
.eval-button {
  opacity: 0;
  &:hover{
    color: red !important;
  }
  &.visible{
    opacity: 1;
  }
}
.rotate-arrow {
  transform: rotate(-90deg);
}
.no-shadow {
  box-shadow: 1px 1px 1px !important;
}
.fit-on-header {
  flex-grow: 0;
}
.eval-intent {
  z-index: 1;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  &.debug {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
  }
  .open {
    width: 15px;
    opacity: 1;
    visibility: visible;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
    z-index: 10;
    &.debug {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
    }

    .v-icon {
      color: white;
    }
    &.view-suggestion {
      visibility: hidden;
      width: 0px;
      opacity: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
  // .invalid-suggest {
  //   background-color: red !important;
  //   opacity: 0.8;
  // }
  // .below-message {
  //   width: 15px;
  //   margin-left: -20px;
  //   background-color: whitesmoke;
  //   &.debug {
  //     margin-left: 0px;
  //     margin-right: -15px;
  //   }
  // }
  .close {
    width: 0;
    transition: all 0.4s 0.1s ease-in-out;
    opacity: 0;
    visibility: hidden;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
    outline: none;
    border: none;
    box-shadow: none;
    &.debug {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
    }
    .v-toolbar {
      border-top-right-radius: 7px;
      &.debug {
        border-top-right-radius: 0px;
        border-top-left-radius: 5px;
      }
      .v-toolbar__content {
        padding: 0;
      }
    }
    &.view-suggestion {
      visibility: visible;
      width: 10px;
      opacity: 1;
      outline: none;
      border: none;
      box-shadow: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .content {
    width: auto;
    max-width: 0px;
    transition: all 0.4s 0.1s ease-in-out;
    opacity: 0;
    visibility: hidden;
    white-space: nowrap;
    &.view-suggestion {
      visibility: visible;
      width: auto;
      max-width: 220px;
      opacity: 1;
    }
  }
  .btn-valid-intent {
    margin: 3px 8px;
  }
  .btn-valid-intent:not(:hover) {
    .v-icon {
      color: gray;
    }
  }
  .validated {
    .v-icon {
      color: inherit !important;
    }
  }
}
</style>