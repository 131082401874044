<template>
  <v-layout class="row-message" ma-2 grow>
    <v-layout v-if="event.content_type == 'sticker'" column>
      <div style="
          width: 100px;
          max-height: 100px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          max-width: 100%;
          margin-bottom: 10px;
          border-radius: 4px;
        ">
        <viewer style="display: flex"
          :images="[event.content.url]"
          :options="{ movable: true, title: false, navbar: false }">
          <img v-for="src in [event.content.url]" :src="src"
            :key="src" style="width: 100%; cursor: pointer" />
        </viewer>
      </div>
    </v-layout>
    <v-card v-else class="message" :class="`${this.$vuetify.theme.dark ? 'secondary-background' : 'primary-background'}`" >
      <v-layout grow > 
        <v-layout class="box-message" column>
          <v-layout class="dont-break-out">
            <v-flex v-if="event.content">

              <v-layout v-if="event.content_type == 'image'" column>
                <div style="
                    width: 330px;
                    max-height: 330px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    max-width: 100%;
                    margin-bottom: 10px;
                    border-radius: 4px;
                  ">
                  <viewer style="display: flex"
                    :images="[event.content.url]"
                    :options="{ movable: true, title: false, navbar: false }">
                    <img v-for="src in [event.content.url]"
                      :src="src" :key="src"
                      style="width: 100%; cursor: pointer" />
                  </viewer>
                </div>
                <span v-if="event.content.caption">{{
                  event.content.caption
                }}</span>
              </v-layout>

              <v-layout v-else-if="event.content_type == 'ptt'" column>
                <audio-player :file="event.content.url">
                </audio-player>
              </v-layout>

              <v-layout v-else-if="event.content_type == 'audio'"
                column>
                <audio-player :file="event.content.url">
                </audio-player>
              </v-layout>

              <v-layout v-else-if="event.content_type == 'text' || event.content_type == 'click'"
                column>
                <span>
                  {{ event.content.text }}
                </span>
              </v-layout>

              <v-layout v-else-if="event.content_type == 'video'"
                column>
                <video v-show="event.content.url" width="300"
                  height="300" controls
                  style="outline: none; margin-bottom: 10px; border-radius: 4px">
                  <source :src="event.content.url"
                    type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </v-layout>

              <v-layout v-else-if="event.content_type == 'document'"
                column>
                <div
                  v-if="checkDocType(event.content.caption, ['pdf'])"
                  style="
                    width: 330px;
                    max-height: 330px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    max-width: 100%;
                    margin-bottom: 10px;
                    border-radius: 4px;
                  ">
                  <div class="pdfPreview"
                    v-for="src in [event.content.url]" :src="src"
                    :key="src">
                    <a @click="viewPdf(src)">
                      <span>
                        <object :data="src" type="application/pdf"></object>
                      </span>
                    </a>
                  </div>
                </div>
                <v-layout ma-1 align-center>
                  <div style="height: 33px">
                    <img v-if="
                        checkDocType(event.content.caption, ['pdf'])
                      " style="height: 100%" src="icon_pdf.png" />
                    <img v-else-if="
                        checkDocType(event.content.caption, [
                          'rtf',
                          'doc',
                          'docx',
                        ])
                      " style="height: 100%" src="icon_doc.png" />
                    <img v-else-if="
                        checkDocType(event.content.caption, [
                          'xls',
                          'xlsx',
                          'csv',
                        ])
                      " style="height: 100%" src="icon_sheet.png" />
                    <img v-else style="height: 100%" src="icon_file.png" />
                  </div>

                  <span class="mr-1 ml-2">{{
                    event.content.caption
                  }}</span>

                  <v-btn outlined icon color="#848484" @click="
                      downloadUri({ url: event.content.url, file_name: event.content.file_name || event.content.caption })
                    ">
                    <v-icon>get_app</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="checkDocType(event.content.caption, ['pdf'])"
                    outlined icon color="#848484"
                    @click="viewPdf(event.content.url)">
                    <v-icon>preview</v-icon>
                  </v-btn>
                </v-layout>
              </v-layout>

              <v-layout v-else-if="event.content_type == 'contact'"
                column>
                <span v-for="contact in event.content.contacts"
                  v-bind:key="contact">
                  <h3>
                    {{ contact.name.firstName }} {{ contact.name.lastName }}
                  </h3>
                  <span v-for="phone in contact.phones"
                    v-bind:key="phone">{{ phone.type }}:
                    <i>{{ phone.phone }}</i><br /></span>
                  <span v-for="email in contact.emails"
                    v-bind:key="email">Email:
                    <i>{{ email.email }}</i><br /></span>
                  <span v-for="address in contact.addresses"
                    v-bind:key="address">Endereço:
                    <i>{{ address.street }}</i><br /></span>
                </span>
              </v-layout>

              <v-layout v-else-if="event.content_type == 'location'"
                column>
                <h3>Localização</h3>
                <span>Latitude:
                  <i>{{ event.content.latitude }}</i></span>
                <span>Longitude:
                  <i>{{ event.content.longitude }}</i></span>
              </v-layout>

              <v-layout v-else-if="event.content_type == 'unsupported'"
                column>
                <span><i>(Formato da mensagem não suportado)</i></span>
              </v-layout>

              <v-layout v-else column>
                {{ event.content }}
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout align-end>
            <v-layout>
              <span v-if="event.sender"
                class="from-number">+{{ event.sender }}</span>
            </v-layout>
            <v-layout shrink>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span class="date-event" v-on="on">{{
                    (event.created_at) | moment("from", "now")
                  }}</span>
                </template>
                <span>{{
                  (event.created_at) | moment("DD/MM/YYYY HH:mm:ss")
                }}</span>
              </v-tooltip>
            </v-layout>
          </v-layout>
        </v-layout>

        <v-layout>
          <eval-intent :message="event"
            @assignConversation="assignConversation">
          </eval-intent>
        </v-layout>
      </v-layout>
    </v-card>

    <v-spacer v-if="!debug"></v-spacer>
    <previewPDF ref="previewPDF"></previewPDF>
  </v-layout>
</template>

<script>
import previewPDF from "./previewPDF"
import EvalIntent from './EvalIntent'
import AudioPlayer from '@/components/Common/AudioPlayer'

import { mapActions } from "vuex"

export default {
  props: ['event', 'debug'],
  components: {
    EvalIntent,
    AudioPlayer,
    previewPDF,
  },
  data() {
    return {
      readNow: false
    }
  },
  methods: {
    ...mapActions("rasaUpload", {
      downloadUri: "download",
    }),
    viewPdf(src) {
      this.$refs.previewPDF.open(src);
      this.$refs.previewPDF.dialog = true;
    },
    assignConversation() {
      this.$emit('assignConversation')
    },
    checkDocType(file_name, types) {
      let name_splitted = file_name.split('.')
      let type = name_splitted[name_splitted.length - 1]
      return types.indexOf(type) > -1
    },
  },
}
</script>

<style lang="scss" scoped>
.row-message {
  max-height: max-content;
  padding: 0;

  .message {
    border-radius: 12px 12px 12px 0;

    display: flex;
    align-self: flex-start;
     z-index: 100;

    .box-message {
      padding: 8px 12px;
      border-radius: 12px 12px 12px 0;
      z-index: 100;
      min-width: 240px;
      max-width: 440px;

      .dont-break-out {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }

      &.debug {
        border-radius: 12px 12px 0 12px;
      }
    }

    .from-number {
      font-weight: 400;
      line-height: 18px;
      font-size: 12px;
      color: gray;
    }

    .date-event {
      color: gray;
      font-size: 12px;
      margin-left: 10px;
    }

    .box-status {
      min-width: 17px;
      margin-top: -5px;
    }

    &.debug {
      border-radius: 12px 12px 0 12px;
    }
  }

  .shorcut-message {
    min-width: 40px;

    .btn-shortcut {
      opacity: 0;
    }

    &:hover {
      .btn-shortcut {
        opacity: 1;
      }
    }
  }
}
</style>
