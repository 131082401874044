<template>
  <v-dialog v-model="dialog" width="650" style="height:100%" @keydown.esc="close">
    <v-card height="100vh">
      <object :data="pdf" type="application/pdf" width="100%" height="100%"></object>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      pdf: {},
      dialog: false
    };
  },
  methods: {
    open(pdf) {
      this.pdf = pdf;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
